import React, { Fragment } from 'react';
import { Box, Grid, CircularProgress } from '@mui/material';
import ProductCard from '../cards/productCard';

export default function ProductList(props) {
  const { products, loading } = props;

  return (
    <>
      {loading === false ? (
        <>
          {products.length > 0 ? (
            <Box p={6}>
              <Grid
                container
                spacing={3}
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                {products.map((product) => {
                  return (
                    <Fragment key={product.id}>
                      <Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
                        <ProductCard product={product} />
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>
            </Box>
          ) : null}
        </>
      ) : (
        <Box p={12}>
          <Grid
            container
            spacing={3}
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress />
          </Grid>
        </Box>
      )}
    </>
  );
}
