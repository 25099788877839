import Image from 'next/image';
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

export default function SlideShow() {
  const images = [
    '/slideImages/Capture-1.jpg',
    '/slideImages/Capture-2.jpg',
    '/slideImages/Capture-3.jpg',
    '/slideImages/Capture-4.jpg',
    '/slideImages/Capture-5.jpg',
    '/slideImages/Capture-6.jpg',
    '/slideImages/Capture-7.jpg',
    '/slideImages/Capture-8.jpg',
  ];

  const zoomOutProperties = {
    duration: 2000, // The time it takes (milliseconds) before next transition starts
    transitionDuration: 500, // Determines how long the transition takes
    infinite: true, // Specifies if the transition should loop infinitely
    indicators: true, // For specifying if there should be dots below the slideshow.
    scale: 0.4, // Required when using zoom to specify the scale the current slide should be zoomed to. A number greater than 1 indicates zoom in. A number less than 1, indicates zoom out
    arrows: true, // Determines if there should be a navigational arrow for going to the next or previous slide
  };

  return (
    <>
      <Zoom {...zoomOutProperties}>
        {images.map((each, index) => (
          <Image
            key={index}
            width={6795}
            height={2490}
            src={each}
            alt={index}
          />
        ))}
      </Zoom>
    </>
  );
}
