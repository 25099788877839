import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import styles from '../../styles/productCard.module.css';
import { getProductCardImage } from '../../src/dataProvider';
import PhotoViewModal from '../modals/photoViewModal';

export default function ProductCard(props) {
  const { product } = props;
  const router = useRouter();
  const { t } = useTranslation();
  const [image, setImage] = useState('');
  const [openViewPhoto, setOpenViewPhoto] = useState(false);

  const getImageData = async () => {
    if (product?.file_id) {
      const imageData = await getProductCardImage(product?.file_id);
      setImage('data:image/jpeg;base64,' + imageData.image);
    } else {
      setImage(`/static/${process.env.CHAMBER_NAME}/logo.png`);
    }
  };

  useEffect(() => {
    getImageData();

    return () => {
      setImage({});
    };
  }, []);

  const redirectDetailProduct = () => {
    router.push('/product/' + product.id + '?title=' + product.title);
  };

  const handleCloseViewPhoto = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenViewPhoto(false);
  };

  const handleOpenViewePhoto = () => {
    setOpenViewPhoto(true);
  };

  return (
    <>
      <PhotoViewModal
        openViewPhoto={openViewPhoto}
        handleCloseViewPhoto={handleCloseViewPhoto}
        productLogo={image}
      />

      <Card sx={{ height: 480, width: '100%' }} elevation={5}>
        <CardMedia
          component='img'
          height='200'
          image={image}
          alt={product?.title}
          //onClick={handleOpenViewePhoto}
          onClick={redirectDetailProduct}
          sx={{ cursor: 'pointer' }}
        />

        <CardContent sx={{ height: 220 }}>
          <Typography gutterBottom variant='h5' component='div'>
            {product?.title}
          </Typography>
          <Box className={styles.descriptionBox}>
            <Typography
              variant='body2'
              //color='text.secondary'
              textAlign='justify'
              dangerouslySetInnerHTML={{
                __html: product?.description,
              }}
            ></Typography>
          </Box>
        </CardContent>

        <CardActions sx={{ height: 70 }}>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-end'
            alignContent='flex-end'
          >
            <Button
              size='small'
              className={styles.learnMoreButton}
              onClick={redirectDetailProduct}
            >
              {t('learnMoreButton')}
            </Button>
          </Grid>
        </CardActions>
      </Card>
    </>
  );
}
