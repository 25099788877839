import { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import styles from '../styles/Home.module.css';
import { getProducts } from '../src/dataProvider';
import ProductList from '../components/productList';
import SecondTopBar from '../components/secondTopBar';
import SlideShow from '../components/slideShow';
import TopBar from '../components/topBar';

export async function getStaticProps() {
  return {
    props: {},
    revalidate: 10,
  };
}

export default function Home() {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const getProductData = async () => {
    const data = await getProducts(i18n.language);
    setProducts(data.products);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getProductData();

    return () => {
      setProducts([]);
    };
  }, [i18n.language]);

  return (
    <>
      {/*  <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '1000px',
        }}
      >
        <Image
          alt='products'
          src='/main.jpg'
          layout='fill'
          objectFit='cover'
          quality={100}
          className={styles.bgWrap}
          priority
        />
      </div> */}

      {/* <div>
        <Image
          alt='products'
          src='/main2.jpg'
          width={3239}
          height={1220}
          className={styles.bgWrap}
          priority
        />
      </div> */}

      <div>
        <TopBar />
      </div>

      <Box>
        <SlideShow />
      </Box>

      <Box pt={2} style={{ paddingTop: 5 }}>
        <Typography
          variant='h4'
          gutterBottom
          textAlign='center'
          className={styles.productTitle}
        >
          {t('titleDescription')}
        </Typography>
        <Typography
          className={styles.productDescription}
          gutterBottom
          textAlign='center'
          fontStyle='italic'
        >
          {t('description')}
        </Typography>
      </Box>

      <ProductList products={products} loading={loading} />
    </>
  );
}
