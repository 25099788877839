import { Dialog } from '@mui/material';

export default function PhotoViewModal(props) {
  const { openViewPhoto, handleCloseViewPhoto, productLogo } = props;
  return (
    <>
      <Dialog
        open={openViewPhoto}
        onClose={handleCloseViewPhoto}
        maxWidth='md'
        aria-labelledby='dialog-view-photo'
        fullWidth
      >
        <img src={productLogo} alt='product image' />
      </Dialog>
    </>
  );
}
